<template>
  <v-app>
    <v-main class="login px-8">
      <v-form v-model="valid" lazy-validation>
      <v-row style="height: 100%" justify="center" align-content="center">
        <v-col>
          <v-row class="mb-8" justify="center" align-content="center">
            <v-col sm="8" md="6" lg="5">
              <v-img
                class="login__logo"
                alt="Branding Technology"
                src="../assets/images/bblogo.png"
              ></v-img>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0 mb-2" justify="center" align-content="center">
            <v-col sm="5" md="4" lg="4">
              <v-text-field
                class="login__text-field"
                v-model="userName"
                prepend-inner-icon="mdi-email"
                label="E-mail"
                type="email"
                background-color="white"
                outlined
                hide-details
                :autofocus="true"
                :rules="[v => !!v || '', v => /.+@.+\..+/.test(v) || '']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0 mb-8" justify="center" align-content="center">
            <v-col sm="5" md="4" lg="4">
              <v-text-field
                class="login__text-field"
                v-model="password"
                prepend-inner-icon="mdi-lock"
                label="Password"
                type="password"
                background-color="white"
                outlined
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0 mb-2" justify="center" align-content="center">
            <v-col sm="5" md="4" lg="4">
              <v-btn
                class="login__btn pa-3 mr-3 text-body-1"
                width="100%"
                height="56px"
                color="primary"
                large
                depressed
                @click="login()"
                :disabled="!this.userName || !this.password || !valid"
              >
                ログイン
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      </v-form>
    </v-main>
    <v-overlay
      :absolute="true"
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      valid: true,
      overlay: false,
      userName: null,
      password: null,
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'nextPage']),
  },
  methods: {
    ...mapActions(['canLogin', 'reflectSelectorItem']),

    async login() {
      this.overlay = true

      try {
        await this.canLogin({eMail: this.userName, password: this.password});
        await this.reflectSelectorItem();

      } catch (error) {
        console.error(error)
        this.$store.commit('setToast', {msg: 'ログインに失敗しました\nユーザー/パスワードを確認ください', color: 'red', timeout: 4000});
      }
      this.overlay = false
    },
  },
  watch: {
    userInfo(val) {
      if(val) {
        let nextPage = '/top'

        if(this.nextPage) {
          nextPage = this.nextPage
          this.$store.commit('nextPage', null)
        }

        this.$router.push(nextPage)
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.login {
  background-color: #f2f8ff;
  height: 100vh;
  overflow-y: auto !important;

  &__logo {
    max-width: 570px;
  }

  &__text-field {
    max-width: 420px;
  }

  &__btn {
    max-width: 420px;
  }
}
</style>
